import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

const GoogleButton = ({ link }) => {
  return (
    <a
      href="{{ url_for('bp_oauth2.bp_oauth2_google_client.GoogleLoginEndpoint') }}"
      className="btn mx-1 p-2 btn-lg"
    >
      <i className="bi bi-google" style={{ color: "#dd4b39" }}></i>
    </a>
  );
};

GoogleButton.propTypes = {
  link: PropTypes.string,
};

export default GoogleButton;
