// Access the strings used on the birthdayGender page.
export const t_birthdayGender = {
  title: "signup.birthdayGender.title",
  subtitle: "signup.birthdayGender.subtitle",
  dayPlaceholder: "signup.birthdayGender.dayPlaceholder",
  monthPlaceholderSelect: "signup.birthdayGender.monthPlaceholderSelect",
  monthPlaceholderSelectOptions:
    "signup.birthdayGender.monthPlaceholderSelectOptions",
  yearPlaceholder: "signup.birthdayGender.yearPlaceholder",
  genderPlaceholderSelect: "signup.birthdayGender.genderPlaceholderSelect",
  genderPlaceholderSelectOptions:
    "signup.birthdayGender.genderPlaceholderSelectOptions",
  genderPlaceholder: "signup.birthdayGender.genderPlaceholder",
  pronounPlaceholderSelect: "signup.birthdayGender.pronounPlaceholderSelect",
  pronounPlaceholderSelectOptions:
    "signup.birthdayGender.pronounPlaceholderSelectOptions",
};
