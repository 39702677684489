import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import React, { useEffect, useState } from "react";

import "../assets/css/animation.css";
import { useTranslation } from "react-i18next";
import { t_help } from "../i18n/usualTexts/t_help";

import { Listbox } from "@headlessui/react";
import FormField from "./Field";

export interface FormSelectProps {
  name: string;
  onChange: Function;
  value: string;
  immediateValidation?: boolean;
  errors?: Set<string>;
  required?: boolean;
  submitted?: boolean;
  options: Array<string>;
  toPresentErrors?: boolean;
  children: string;
}

interface PropsBox {
  value: string;
  selectedValue: string;
}

const BoxListOption: React.FC<PropsBox> = ({ value, selectedValue }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={value === selectedValue ? { backgroundColor: "lightgray" } : {}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={isHovered ? { backgroundColor: "lightblue" } : {}}>
        <Listbox.Option
          value={value}
          style={{ marginRight: "8px", cursor: "pointer", padding: "8px" }}
        >
          {value}
        </Listbox.Option>
      </div>
    </div>
  );
};

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  onChange,
  value,
  immediateValidation = true,
  errors = new Set(),
  required = false,
  submitted = false,
  options,
  toPresentErrors = true,
  children,
}) => {
  const { t } = useTranslation();
  const requiredErrorMessage = t(t_help.fieldOrSelect, {
    placeholder: children,
  });

  if (submitted && !value && required) {
    errors.clear();
    errors.add(requiredErrorMessage);
  } else if (value) {
    errors.delete(requiredErrorMessage);
  }

  const [selectedValue, setSelectedValue] = useState(value);

  const onSelect = (value: string) => {
    setSelectedValue(value);
    onChange(value, name);
  };

  return (
    <div className={`form-floating ${
      errors.size ? ("is-invalid" + (submitted ? " shake-animation" : "")) : ""
    }`}>
      <div style={{ position: "relative" }}>
        <Listbox value={selectedValue} onChange={onSelect}>
          <Listbox.Button
            style={{
              border: "none",
              paddingInline: "0",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <div style={{ pointerEvents: "none" }}>
              <FormField
                name={name}
                type="text"
                value={selectedValue}
                errors={errors}
                submitted={submitted}
                toPresentErrors={false}
                autofocus={false}
                immediateValidation={immediateValidation}
              >
                {children}
              </FormField>
            </div>
          </Listbox.Button>
          <Listbox.Options
            style={{
              position: "absolute",
              top: "100%",
              left: "0%",
              width: "100%",
              background: "white",
              border: "1px solid lightgray",
              borderRadius: "5px",
              listStyle: "none",
              maxHeight: "130px",
              overflowY: "auto",
              padding: "0",
              zIndex: "3",
            }}
          >
            {options.map((value: string) => (
              <BoxListOption key={value} value={value} selectedValue={selectedValue} />
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
      
      {errors.size > 0 &&
        toPresentErrors &&
        [...errors].map((error) => (
          <div key={error} className={"invalid-feedback d-block" + (submitted ? " shake-animation" : "")}>
            {error}
          </div>
        ))}
    </div>
  );
};

export default FormSelect;


