import { OpenAPI } from "../service/auth";

export const ComposeURLWithGetQueryArguments = (url) => {
  const currentQueryString = window.location.search;
  return url + (!currentQueryString ? "" : currentQueryString);
};

export const ComposeURLWithResponseParameters = (redirectionResponse) => {
  const url = OpenAPI.BASE;
  const location = redirectionResponse.location;
  const parameters = redirectionResponse.parameters
    ? "?" + Object.keys(redirectionResponse.parameters).map((key) =>`${encodeURIComponent(key)}=${encodeURIComponent(redirectionResponse.parameters[key])}`).join("&")
    : "";

  return url + location + parameters;
};
