/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AuthorizationCodeGrant = {
    /**
     * Grant type for authorization code
     */
    grant_type: AuthorizationCodeGrant.grant_type;
    /**
     * Authorization code received from authorization server
     */
    code: string;
    /**
     * Client secret associated to client ID
     */
    client_secret: string;
    /**
     * Client identifier issued to the client during registration
     */
    client_id: string;
};

export namespace AuthorizationCodeGrant {

    /**
     * Grant type for authorization code
     */
    export enum grant_type {
        AUTHORIZATION_CODE = 'authorization_code',
    }


}
