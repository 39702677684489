import { TFunction } from "i18next";
import {
  T_oAuth2Type,
  t_warningTexts,
} from "../../../i18n/usualTexts/t_warningTexts";

interface ErrorMessages {
  username: {
    errorType: string;
    formatError: string;
    invalidException: string;
  };
  password: {
    errorType: string;
    formatError: string;
    invalidException: string;
  };
}

const errorType = {
  username: "UserNotFoundError",
  password: "InvalidUserCredentialsError",
};

export function showAuthError(
  type: string,
  error: any,
  setServerErrorPopUp: React.Dispatch<React.SetStateAction<string | null>>,
  fieldErrors: Set<string>,
  t: TFunction<"translation", undefined>
) {

  console.log(error);
  fieldErrors.clear();

  if (error.error)
    fieldErrors.add(
      t(t_warningTexts.oAuth2[type as keyof T_oAuth2Type].formatError)
    );
  else if (error.body) {
    if (error.body.exception_uuid)
      setServerErrorPopUp(error.body.exception_uuid);
    else if (
      error.body.title === errorType[type as keyof ErrorMessages]
    )
      fieldErrors.add(
        t(t_warningTexts.oAuth2[type as keyof T_oAuth2Type].invalidException)
      );
    else setServerErrorPopUp(error.body.title);
  } else if (error.message) setServerErrorPopUp(error.message);
}
