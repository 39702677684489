export const cleanFormDataErrors = (formData) => {
  Object.keys(formData).map((data) => formData[data].errors.clear());
};

export const cleanStringsObject = (object) => {
  Object.keys(object).map((key) => (object[key] = ""));
};

export const is_different_previous_value = (contextData, formData) => {
  let isUnequal = false;
  
  const comparision_oldValue_nowValue = (key) => {
    if (typeof formData[key].value === "object") 
      return contextData[key].value  !== formData[key].value.value;
    else return contextData[key] !== formData[key].value;
  };

  Object.keys(formData).map((key) => {
    if (comparision_oldValue_nowValue(key)) isUnequal = true;
  });

  return isUnequal;
};

export const fields_changed = (formData, accountData) => {
  let isChanged = false;
  
  const nowValue = (key) => {
    if (typeof formData[key].value === "object") 
      return formData[key].value.value;
    else return formData[key].value;
  };

  Object.keys(formData).map((key) => {
    if (localStorage.getItem(key) !== nowValue(key)) isChanged = true;
  });

  return isChanged;
};

export const set_localStorage = (formData) => {
  Object.keys(formData).map((key) =>
    localStorage.setItem(key, formData[key].value ?? "")
  );
};

export function clearLocalStorage() {
  Object.keys(localStorage).forEach((key) => localStorage.setItem(key, ""));
}
