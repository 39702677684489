/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Successful response schema for OAuth2 client validation.
 */
export type SuccessTokenResponse = {
    /**
     * JWT access token.
     */
    access_token: string;
    /**
     * JWT refresh token.
     */
    refresh_token?: string;
    /**
     * Token type (e.g., Bearer).
     */
    token_type: SuccessTokenResponse.token_type;
    /**
     * Timestamp in POSIX format.
     */
    issued_at: number;
    /**
     * Timestamp in POSIX format.
     */
    expiration_time: number;
    /**
     * Timestamp in POSIX format.
     */
    not_before?: number;
};

export namespace SuccessTokenResponse {

    /**
     * Token type (e.g., Bearer).
     */
    export enum token_type {
        BEARER = 'bearer',
    }


}
