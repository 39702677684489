import React from "react";

import IdentityPage from "./feature/authentication/page/Identity";
import PasswordPage from "./feature/authentication/page/Password";
import PagePaths from "./constant/PagePaths";
import BirthdayGenderPage from "./feature/createaccount/page/BirthdayGenderPage";
import CreateAccountPage from "./feature/createaccount/page/CreateAccountPage";
import InsertUsernamePage from "./feature/createaccount/page/InsertUsernamePage";
import CreatePasswordPage from "./feature/createaccount/page/CreatePasswordPage";
import CongratulationsPage from "./feature/createaccount/page/CongratulationsPage";
import InsertUsernameCodePage from "./feature/createaccount/page/InsertUsernameCode";
import SecondFactorPage from "./feature/authentication/page/SecondFactor";

export const stepsAuthentication = [
  {
    stepNumber: 1,
    page: PagePaths.LOGIN.IDENTITY,
    requiredOAuthParams: ["client_id", "redirect_uri", "response_type"],
    element: <IdentityPage />,
  },
  {
    stepNumber: 2,
    page: PagePaths.LOGIN.PASSWORD,
    requiredOAuthParams: ["client_id", "redirect_uri"],
    element: <PasswordPage />,
  },
  {
    stepNumber: 3,
    page: PagePaths.LOGIN.SECONDFACTOR,
    requiredOAuthParams: ["client_id", "redirect_uri"],
    element: <SecondFactorPage />,
  },
];

export const stepsCreateAccount = [
  {
    stepNumber: 1,
    page: PagePaths.SIGNUP.INSERTUSERNAME,
    element: <InsertUsernamePage />,
  },
  {
    stepNumber: 2,
    page: PagePaths.SIGNUP.CREATEACCOUNT,
    element: <CreateAccountPage />,
  },
  {
    stepNumber: 3,
    page: PagePaths.SIGNUP.BIRTHDAYGENDER,
    element: <BirthdayGenderPage />,
  },
  {
    stepNumber: 4,
    page: PagePaths.SIGNUP.CREATEPASSWORD,
    element: <CreatePasswordPage />,
  },
  {
    stepNumber: 5,
    page: PagePaths.SIGNUP.VERIFYEMAIL,
    element: <InsertUsernameCodePage />,
  },
  {
    stepNumber: 8,
    page: PagePaths.SIGNUP.CONGRATULATIONS,
    element: <CongratulationsPage />,
  },
];
