import React, { ReactNode, useEffect } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useAuthentication } from "../context/AuthenticationContext";
import PagePaths from "../constant/PagePaths";
import { ComposeURLWithGetQueryArguments } from "../helper/get";

type RenderAuthenticationProps = { children: ReactNode };
export const RenderAuthentication = ({
  children,
}: RenderAuthenticationProps) => {
  const navigate: NavigateFunction = useNavigate();

  const { authData } = useAuthentication();

  const location = useLocation();

  useEffect(() => {
    const stepPageMapping: { [key: number]: string } = {
      1: PagePaths.LOGIN.IDENTITY,
      2: PagePaths.LOGIN.PASSWORD,
      3: PagePaths.LOGIN.SECONDFACTOR,
    };

    const currentStep = authData.step ?? 1;
    const currentPage = location.pathname;

    if (!currentPage.startsWith("/login/")) return;

    if (currentPage !== stepPageMapping[currentStep]) {
      navigate(ComposeURLWithGetQueryArguments(stepPageMapping[1]));
      return;
    }

    navigate(ComposeURLWithGetQueryArguments(stepPageMapping[currentStep]));
  }, [authData.step, authData.username, navigate, location.pathname]);

  return <>{children}</>;
};
