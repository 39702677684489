const ptBrTranslations = {
  translations: {
    help: {
      nextButton: "Próximo",
      backButton: "Voltar",
      defaultSelect: "Selecione uma opção...",
      showPasswordCheck: "Mostrar senha",
      timeForNewCodeText: "Receber novo código ({{seconds}} segundos)",
      timeForNewCodeLink: "Enviar novo código",
      check: "Marque a caixa de seleção para continuar.",
      fieldOrSelect: "O campo {{placeholder}} é obrigatório.",
    },

    login: {
      identity: {
        title: "Entra",
        subtitle: "Use sua Conta Housien",
        emailPlaceholder: "E-mail",
        forgotEmailLink: "Esqueceu seu e-mail?",
        guestModeText:
          "Não é seu computador? Use o modo visitante para conectar demaneira privada.",
        guestModeLink: "Mais detalhes, por favor!",
        createAccountButton: "Criar conta",
        nextButton: "Próximo",
        orConnectWithText: "Ou se conecte com",
      },

      password: {
        title: "Olá, {{username}}",
        subtitle: "Confirme sua identidade",
        passwordPlaceholder: "Senha",
        forgotPasswordLink: "Esqueceu sua senha?",
      },
    },

    signup: {
      createAccount: {
        title: "Criar uma Conta Housien",
        subtitle: "Insira seu Nome e sua Nacionalidade",
        namePlaceholder: "Nome",
        surnamePlaceholder: "Sobrenome (opcional)",
        nationalityPlaceholder: "De onde você é?",
        serviceTermsCheck: "Concordo com os",
        serviceTermsLink: "Termos de Serviço",
      },

      birthdayGender: {
        title: "Informações básicas",
        subtitle: "Digite sua data de nascimento e gênero",
        dayPlaceholder: "Dia",
        monthPlaceholderSelect: "Mês",
        monthPlaceholderSelectOptions:
          "Janeiro,Fevereiro,Março,Abril,Maio,Junho,Julho,Agosto,Setembro,Outubro,Novembro,Dezembro",
        yearPlaceholder: "Ano",
        genderPlaceholderSelect: "Gênero",
        genderPlaceholderSelectOptions:
          "Feminino,Masculino,Personalizar,Prefiro não dizer",
        genderPlaceholder: "Qual é o seu gênero",
        pronounPlaceholderSelect: "Refira-se a mim como",
        pronounPlaceholderSelectOptions: "Feminino,Masculino,Outro",
      },

      insertUsername: {
        title: "Como você fará Login",
        subtitle:
          "Insira um endereço de e-mail para fazer login na sua Conta Housien",
        emailPlaceholder: "E-mail",
      },

      createPassword: {
        title: "Crie uma Senha",
        subtitle:
          "Crie uma senha forte com uma combinação de letras, números e símbolos",
        passwordPlaceholder: "Senha",
        confirmPasswordPlaceholder: "Confirmar Senha",
      },

      insertUsernameCode: {
        title: "Verifique seu E-mail",
        subtitle:
          "Parabéns por criar sua Conta Housien. Agora digite o código de verificação enviado para o e-mail inserido",
        codePlaceholder: "Inserir código",
      },

      congratulations: {
        title: "Conta Housien Criada",
        subtitle: "Parabéns por finalizar sua Conta Housien!",
        bodyText:
          "Agora se conecte com sua conta para poder aproveitar todos os nossos serviços.",
        loginLink: "Conecte-se",
      },
    },

    popUp: {
      titleError: "Tivemos um erro",
      titleNetworkError: "Conexão falha",
      bodyTextError: "Tente novamente mais tarde. ",
      bodyTextNetworkError: "Verifique sua rede. ",
      bodyText: "Caso persistir entre em contato conosco informando o código de erro",
      contactSubtitle: "Contato",
      moreDetailsLink: "Mais Detalhes",
    },

    warningTexts: {

      oAuth2: {
        usernameFormatError: "Coloque o E-mail corretamente.",
        usernameInvalidException: "Usuário não registrado.",
        passwordFormatError: "Senha deve conter 8 caracteres.",
        passwordInvalidException: "Senha Incorreta. Tente Novamente.",
      },

      signup: {
        confirmPassword: "As senhas não são iguais. Tente novamente.",
        date: "Insira uma data valida.",
        name: "Insira seu {{id}} corretamente",
        password: {
          beginning: "A senha deve conter pelo menos",
          length: "8 caracteres,",
          number: "um número,",
          uppercase: "uma letra maiúscula,",
          lowercase: "uma letra minúscula,",
          specialCharacter: "um caractere especial,",
        },
        select: "Insira um {{id}} válido.",
        username: "Insira um email válido.",
        usernameAlreadyExistsError: "Usuário já registrado.",
        code: "Código inválido. Tente Novamente.",
      },

      date: {
        year: "Insira um ano válido.",
        month: "Insira um mês válido.",
        day: "Insira um dia valido.",
        age: "Idade abaixo do necessário.",
      },

    },
  },
};

export default ptBrTranslations;
