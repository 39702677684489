// Access the strings used on the components.
export const t_help = {
  nextButton: "help.nextButton",
  backButton: "help.backButton",
  defaultSelect: "help.defaultSelect",
  showPasssowrdCheck: "help.showPasswordCheck",
  timeForNewCodeText: "help.timeForNewCodeText",
  timeForNewCodeLink: "help.timeForNewCodeLink",
  check: "help.check",
  fieldOrSelect: "help.fieldOrSelect",
};
