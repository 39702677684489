const enUsTranslations = {
  translations: {
    help: {
      nextButton: "Next",
      backButton: "Back",
      defaultSelect: "Select an option...",
      showPasswordCheck: "Show password",
      timeForNewCodeText: "Receive new code ({{seconds}} seconds)",
      timeForNewCodeLink: "Send new code",
      check: "Check the checkbox to continue.",
      fieldOrSelect: "The field {{placeholder}} is required.",
    },

    login: {
      identity: {
        title: "Login",
        subtitle: "Use your Housien",
        emailPlaceholder: "E-mail",
        forgotEmailLink: "Forgot e-mail?",
        guestModeText:
          "Not your computer? Use Guest mode to sign in privately.",
        guestModeLink: "More details please!",
        createAccountButton: "Sign Up",
        nextButton: "Next",
        orConnectWithText: "Or connect with",
      },

      password: {
        title: "Hi, {{username}}",
        subtitle: "Verify it's you",
        passwordPlaceholder: "Password",
        forgotPasswordLink: "Forgot password?",
      },
    },

    signup: {
      createAccount: {
        title: "Create a Housien Account",
        subtitle: "Enter your name and nationality",
        namePlaceholder: "First name",
        surnamePlaceholder: "Last name (optional)",
        nationalityPlaceholder: "Where are you from?",
        serviceTermsCheck: "I agree to the",
        serviceTermsLink: "Service Terms",
      },

      birthdayGender: {
        title: "Basic information",
        subtitle: "Enter your birthday and gender",
        dayPlaceholder: "Day",
        monthPlaceholderSelect: "Month",
        monthPlaceholderSelectOptions:
          "January,February,March,April,May,June,July,August,September,October,November,December",
        yearPlaceholder: "Year",
        genderPlaceholderSelect: "Gender",
        genderPlaceholderSelectOptions: "Female,Male,Custom,Rather not say",
        genderPlaceholder: "What's your gender?",
        pronounPlaceholderSelect: "Please refer to me as",
        pronounPlaceholderSelectOptions: "Female,Male,Other",
      },

      insertUsername: {
        title: "How will you Login",
        subtitle: "Enter an email address to login your Housien Account",
        emailPlaceholder: "E-mail",
      },

      createPassword: {
        title: "Create a Password",
        subtitle:
          "Create a strong password with a mix of letters, numbers and symbols",
        passwordPlaceholder: "Password",
        confirmPasswordPlaceholder: "Confirm Password",
      },

      insertUsernameCode: {
        title: "Verify your E-mail",
        subtitle:
          "Congratulations on creating your Housien Account. Now enter the verification code sent to the email entered",
        codePlaceholder: "Insert code",
      },

      congratulations: {
        title: "Housien Account Created",
        subtitle: "Congratulations on finalizing your Housien Account!",
        bodyText:
          "Now connect with your account to be able to enjoy all of our services.",
        loginLink: "Login",
      },
    },

    popUp: {
      titleError: "We encountered an error",
      titleNetworkError: "Connection Failed",
      bodyTextError: "Please try again later.",
      bodyTextNetworkError: "Check your network.",
      bodyText:
        "If the issue persists, please contact us providing the error code",
      contactSubtitle: "Contact",
      moreDetailsLink: "More Details",
    },

    warningTexts: {
      oAuth2: {
        usernameFormatError: "Place the email correctly.",
        usernameInvalidException: "User not registered.",
        passwordFormatError: "Password must contain 8 characters.",
        passwordInvalidException: "Incorrect Password. Try Again.",
      },

      signup: {
        confirmPassword: "Passwords do not match. Please try again.",
        date: "Enter a valid date.",
        name: "Enter your {{id}} correctly.",
        password: {
          beginning: "Password must contain at least",
          length: "8 characters long,",
          number: "one number,",
          uppercase: "one uppercase letter,",
          lowercase: "one lowercase letter,",
          specialCharacter: "one special character,",
        },
        select: "Enter a valid {{id}}.",
        username: "Enter a valid email.",
        usernameAlreadyExistsError: "User already exists.",
        code: "Invalid code. Try Again.",
      },

      date: {
        year: "Enter a valid year.",
        month: "Enter a valid month.",
        day: "Enter a valid day.",
        age: "Age below required.",
      },
    },
  },
};

export default enUsTranslations;
