// Access the strings used on the createAccount page.
export const t_createAccount = {
  title: "signup.createAccount.title",
  subtitle: "signup.createAccount.subtitle",
  namePlaceholder: "signup.createAccount.namePlaceholder",
  surnamePlaceholder: "signup.createAccount.surnamePlaceholder",
  nationalityPlaceholder: "signup.createAccount.nationalityPlaceholder",
  serviceTermsCheck: "signup.createAccount.serviceTermsCheck",
  serviceTermsLink: "signup.createAccount.serviceTermsLink",
};
