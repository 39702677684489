import React from "react";

import { useOAuth2 } from "../context/OAuth2Context";

import "bootstrap/dist/css/bootstrap.min.css";

const ClientLogo: React.FC = () => {
  const { client } = useOAuth2();

  let result = null;

  if (client) {
    result = (
      <div className="row">
        <div className="col text-center">
          <img
            src={client.clientLogoURL}
            alt=""
            style={{
              maxHeight: "30px",
              marginTop: "15px",
              marginBottom: "5px",
            }}
          />
        </div>
      </div>
    );
  }

  return result;
};

export default ClientLogo;
