
export const getDeviceInfo = async () => {
  // TODO complete this function, replace "MOCKS"s
  const deviceInfos = {
    ip_address: await getLocalIP(),
    device_type: "undefined",
    operating_system: window.navigator.platform,
    browser: {
      name: window.navigator.userAgent,
      version: window.navigator.appVersion,
    },
    screen_resolution: window.screen.width + "x" + window.screen.height,
    locale: window.navigator.language,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    app_version: "undefined",
    model: "undefined",
    manufacturer: "undefined",
  };
  return deviceInfos;
};

async function getLocalIP() {
  try {
    const ip = new Promise((resolve, reject) => {
      const pc = new RTCPeerConnection({ iceServers: [] });
      pc.createDataChannel("");
      pc.createOffer()
        .then((offer) => pc.setLocalDescription(offer))
        .catch((error) => reject("Erro ao criar oferta:" + error));
      pc.onicecandidate = (event) => {
        if (event.candidate) {
          const match = event.candidate.address;
          if (match) {
            resolve(match);
          }
          pc.onicecandidate = null;
          pc.close();
        }
      };
    });

    return ip;

  } catch (error) {
    return "undefined";
  }
}
