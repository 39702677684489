import { DateType } from "../../../context/AccountContext";
import { InvalidValueException } from "../../../helper/exception/Exception";

import { FormData as BirthGenderFormData } from "../page/BirthdayGenderPage";
import { FormData as UsernameFormdata } from "../page/InsertUsernamePage";
import { FormData as PasswordFormData } from "../page/CreatePasswordPage";
import { TFunction } from "i18next";
import { t_warningTexts } from "../../../i18n/usualTexts/t_warningTexts";

interface FormData {
    birthday: {
      value: DateType;
      errors: Set<string>;
    };
    genderOption: {
      value: string;
      errors: Set<string>;
    };
    gender: {
      value: string;
      errors: Set<string>;
    };
    pronoun: {
      value: string;
      errors: Set<string>;
    };
  }

export function showAuthError(
  error: any,
  setServerErrorPopUp: React.Dispatch<React.SetStateAction<string | null>>,
  frontExceptionFunction: Function,
  t: TFunction<"translation", undefined>
) {
  console.log(error);

  if (error.description) {
    const errorDescription = (error as InvalidValueException).description;
    frontExceptionFunction(errorDescription);
  } else if (error.body) {
    if (error.body.title === "UsernameAlreadyExistsError")
    frontExceptionFunction(["username", t(t_warningTexts.signup.usernameAlreadyExistsError) ]);
    else if (error.body.exception_uuid)
      setServerErrorPopUp(error.body.exception_uuid);
    else setServerErrorPopUp(error.body.title);
  } else if (error.message) setServerErrorPopUp(error.message);
}
