import React, { useState, ChangeEvent } from "react";

import {
  AuthType,
  useAuthentication,
} from "../../../context/AuthenticationContext";
import { useOAuth2 } from "../../../context/OAuth2Context";

import { useNavigate } from "react-router-dom";

import AuthenticationForm from "../../../component/AuthenticationForm";
import FormField from "../../../component/Field";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import PagePaths from "../../../constant/PagePaths";
import {
  ComposeURLWithGetQueryArguments,
  ComposeURLWithResponseParameters,
} from "../../../helper/get";

import "bootstrap-icons/font/bootstrap-icons.css";
import { PasswordRequest } from "../service/OAuth2Request";
import { showAuthError } from "../service/OAuth2ShowError";
import OAuth2Service from "../service/OAuth2Service";
import { isSuccessTokenStepResponse } from "../../../helper/isSuccessTokenStepResponse";
import { delay, intervalMiliseconds } from "../../../helper/delay";
import { useTranslation } from "react-i18next";
import { t_password } from "../../../i18n/usualTexts/t_password";
import {
  cleanFormDataErrors,
  fields_changed,
  is_different_previous_value,
} from "../../../helper/FormDataServices";
import {
  CacheService,
  IDENTITY_POST_RESPONSE,
  PASSWORD_POST_RESPONSE,
} from "../../../cache/cacheService";

interface FormData {
  password: {
    value: string;
    errors: Set<string>;
  };
}

const PasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorPopUp, setServerErrorPopUp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { authData, setAuthData } = useAuthentication();
  const { oauth2Data } = useOAuth2();
  const [formData, setFormData] = useState<FormData>({
    password: {
      value: "",
      errors: new Set<string>(),
    },
  });

  return (
    <AuthenticationForm
      title={t(t_password.title, { username: authData.username })}
      subtitle={t(t_password.subtitle)}
      back={back}
      onSubmit={() => {
        handleSubmit();
      }}
      isLoading={isLoading}
      serverErrorPopUp={serverErrorPopUp}
      setServerErrorPopUp={setServerErrorPopUp}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="password"
            type="password"
            value={formData.password.value}
            onChange={handleFormData}
            forgotPasswordText={t(t_password.forgotPasswordLink)}
            forgotPasswordAction={() => {}}
            errors={formData.password.errors}
            required={true}
            submitted={submitted}
          >
            {t(t_password.passwordPlaceholder)}
          </FormField>
        </Col>
      </Row>
    </AuthenticationForm>
  );

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const { value } = e.target;
    formData.password.value = value;
    setFormData({ ...formData });
    cleanFormDataErrors(formData);
  }

  function back() {
    setAuthData({
      ...authData,
      step: 1,
    });

    navigate(ComposeURLWithGetQueryArguments(PagePaths.LOGIN.IDENTITY));
  }

  async function handleSubmit() {
    setSubmitted(true);
    setIsLoading(true);

    const cache = new CacheService(authData, PASSWORD_POST_RESPONSE, authData);
    try {
      const authenticationService: OAuth2Service = new OAuth2Service();
      authenticationService.checkPasswordFormat(formData.password.value);

      await postFormDatas_and_navigate(cache, authData);
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      showAuthError(
        "password",
        error,
        setServerErrorPopUp,
        formData.password.errors,
        t
      );

      cache.postToCache(error);
    } finally {
      setIsLoading(false);
      
      setFormData({
        ...formData,
      });

      await delay(1000);
      setSubmitted(false);
    }
  }

  async function postFormDatas_and_navigate(
    cache: CacheService,
    authData: AuthType
  ) {
    if (!cache.getToCache()) {
      const identityPostReponse = cache.getToCache(IDENTITY_POST_RESPONSE);

      const intervalMilisecondsValue = intervalMiliseconds(identityPostReponse);
      await delay(intervalMilisecondsValue);

      const passwordPostResponse = await PasswordRequest(
        oauth2Data,
        formData.password.value,
        identityPostReponse?.access_token
      );
      console.log(passwordPostResponse);

      if (!isSuccessTokenStepResponse(passwordPostResponse))
        window.location.href = ComposeURLWithResponseParameters(
          passwordPostResponse
        );

      cache.postToCache(passwordPostResponse);
    }

    navigate(ComposeURLWithGetQueryArguments(PagePaths.LOGIN.SECONDFACTOR));

    const step = 3;
    setAuthData({
      ...authData,
      step: step,
    });
  }
};

export default PasswordPage;
