/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ClientCredentialsGrant = {
    /**
     * Grant type for client credentials
     */
    grant_type: ClientCredentialsGrant.grant_type;
    /**
     * Client identifier issued to the client during registration
     */
    client_id: string;
    /**
     * Client secret known only to the client and authorization server
     */
    client_secret: string;
};

export namespace ClientCredentialsGrant {

    /**
     * Grant type for client credentials
     */
    export enum grant_type {
        CLIENT_CREDENTIALS = 'client_credentials',
    }


}
