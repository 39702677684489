import { InvalidValueException } from "../../../helper/exception/Exception";
import { DateType } from "../../../context/AccountContext";
import { userMocks } from "../../../mocks";
import DateService, { dateFormat } from "./DateService";
import { t_warningTexts } from "../../../i18n/usualTexts/t_warningTexts";
import { TFunction } from "i18next";

export interface User {
  username: string;
  password: string;
  name: string;
}

class AccountService {
  t: TFunction<"translation", undefined>;
  constructor(t: TFunction<"translation", undefined>) {
    this.t = t;
  }

  checkConfirmPassword(
    confirmPassword: string,
    password: string,
    field: string
  ) {
    if (confirmPassword === password) return confirmPassword;

    throw new InvalidValueException([
      field,
      this.t(t_warningTexts.signup.confirmPassword),
    ]);
  }

  checkDate(birthday: DateType, field: string, valueMonths: Array<string>) {
    const { day, month, year } = birthday;

    if (day || month || year) {
      const dateService = new DateService(
        day,
        month,
        year,
        field,
        valueMonths,
        this.t
      );

      //Validate Year
      dateService.validateYear();

      //Validate Month
      dateService.validateMonth();

      //Validate Day
      dateService.validateDay();

      //Validate Age
      dateService.validateAge();
    } else {
      throw new InvalidValueException([
        field,
        this.t(t_warningTexts.signup.date),
      ]);
    }
  }

  checkName(name: string, field: string, id: string) {
    const nomePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ'\s-]+$/;

    if (nomePattern.test(name)) return name;

    throw new InvalidValueException([
      field,
      this.t(t_warningTexts.signup.name, { id: id }),
    ]);
  }

  checkPassword(password: string, field: string) {
    const error = this.validatePassword(password);
    if (!error) return password;

    throw new InvalidValueException([field, error]);
  }

  checkSelect(
    gender: string,
    field: string,
    id: string,
    values: Array<string>
  ) {
    if (values.includes(gender)) return gender;

    throw new InvalidValueException([
      field,
      this.t(t_warningTexts.signup.select, { id: id }),
    ]);
  }

  checkTelephone(telephone: string, field: string) {
    const phonePattern = /^\d{11}$/;

    if (!phonePattern.test(telephone))
      throw new InvalidValueException([field, "Insira um telefone válido."]);
  }

  checkUsername(username: string, field: string) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailPattern.test(username))
      throw new InvalidValueException([
        field,
        this.t(t_warningTexts.signup.username),
      ]);
  }

  checkCode(code: string, field: string) {
    const codePattern = /^\d{6}$/;

    if (codePattern.test(code)) {
      return code;
    }

    throw new InvalidValueException([
      field,
      this.t(t_warningTexts.signup.code),
    ]);
  }

  validatePassword(password: string): string {
    const errors = [];

    if (password.length < 8) {
      errors.push(this.t(t_warningTexts.signup.password.length));
    }

    if (!/\d/.test(password)) {
      errors.push(this.t(t_warningTexts.signup.password.number));
    }

    if (!/[A-Z]/.test(password)) {
      errors.push(this.t(t_warningTexts.signup.password.uppercase));
    }

    if (!/[a-z]/.test(password)) {
      errors.push(this.t(t_warningTexts.signup.password.lowercase));
    }

    if (!/[^a-zA-Z0-9\s]/.test(password)) {
      errors.push(this.t(t_warningTexts.signup.password.specialCharacter));
    }

    if (errors.length > 0) {
      errors.unshift(this.t(t_warningTexts.signup.password.beginning));
      errors[errors.length - 1] = errors[errors.length - 1].replace(",", ".");
    };
    return errors.join(" ");
  }
}

export default AccountService;
