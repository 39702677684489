import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import React, { useEffect, useState } from "react";

import "../assets/css/animation.css";
import { Col, Row } from "react-bootstrap";
import TextButton from "./TextButton";
import { useTranslation } from "react-i18next";
import { t_help } from "../i18n/usualTexts/t_help";

export interface Props {
  sendCodeEmail: Function;
}

const TimeForNewVerify: React.FC<Props> = ({ sendCodeEmail }) => {
  const [seconds, setSeconds] = useState(30);
  const { t } = useTranslation();
  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    if (seconds === 30) sendCodeEmail();

    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <Row className="flex-grow-1 text text-lg-start mt-4">
      <Col xs={10}>
        {seconds > 0 ? (
          <span className="link-secondary">
            {t(t_help.timeForNewCodeText, {seconds})}
          </span>
        ) : (
          <TextButton onClick={() => setSeconds(30)}>
            {t(t_help.timeForNewCodeLink)}
          </TextButton>
        )}
      </Col>
    </Row>
  );
};

export default TimeForNewVerify;
