/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OAuth2Client = {
    client_id: string;
    redirect_uri: string;
    response_type: string;
    //response_type: OAuth2Client.response_type;
    scope?: string;
};

export namespace OAuth2Client {

    export enum response_type {
        CODE = 'code',
    }


}
