import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthenticationProvider } from "./context/AuthenticationContext";
import { stepsAuthentication, stepsCreateAccount } from "./flowConfig"; // Import the JavaScript configuration
import PagePaths from "./constant/PagePaths";
import MockAuthenticationSuccessPage from "./feature/authentication/page/Mock";
import { OAuth2Provider } from "./context/OAuth2Context";
import { AccountProvider } from "./context/AccountContext";
import CongratulationsPage from "./feature/createaccount/page/CongratulationsPage";
import { RenderOAuth2 } from "./renderContext/RenderOAuth2";
import { RenderAuthentication } from "./renderContext/RenderAuthentication";
import { RenderAccount } from "./renderContext/RenderAccount";

function App() {
  return (
    <OAuth2Provider>
      <RenderOAuth2>
        <Authentication />
        <CreateAccount />
      </RenderOAuth2>
    </OAuth2Provider>
  );
}

export default App;

export function Authentication() {
  return (
    <AuthenticationProvider>
      <RenderAuthentication>
        <Routes>
          {stepsAuthentication.map((step) => (
            <Route
              key={step.stepNumber}
              path={step.page}
              element={step.element}
            />
          ))}
        </Routes>
      </RenderAuthentication>
    </AuthenticationProvider>
  );
}

export function CreateAccount() {
  return (
    <AccountProvider>
      <RenderAccount>
        <Routes>
          {stepsCreateAccount.map((step) => (
            <Route
              key={step.stepNumber}
              path={step.page}
              element={step.element}
            />
          ))}
          <Route
            key={5}
            path={PagePaths.SIGNUP.CONGRATULATIONS}
            element={<CongratulationsPage />}
          />
        </Routes>
      </RenderAccount>
    </AccountProvider>
  );
}
