/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * This object specifies the details required for a client-side redirection. It includes the target URL ('location'), the HTTP status code indicating the type of redirection ('status_code'), and any additional query parameters ('parameters') that should be included in the redirection request. The client should construct the redirection URL by appending URL-encoded query parameters to the 'location' URL, typically using a pattern like `window.location = "${location}?${queryString}"`, where `queryString` is constructed from the key-value pairs in 'parameters'.
 * 
 */
export type RedirectionResponse = {
    /**
     * The URL to which the client should be redirected. This should be a fully qualified URI that the client can navigate to directly.
 * 
     */
    location: string;
    /**
     * The HTTP status code associated with the redirection. This code indicates the type of redirection and the expected client behavior. Common redirection codes include 302 (Found) for temporary redirections and 301 (Moved Permanently) for permanent redirections. The specific code used may impact client caching and behavior.
 * 
     */
    status_code: RedirectionResponse.status_code;
    /**
     * A set of additional key-value pairs that should be appended to the 'location' URL as query parameters. These should be URL-encoded and appended to the URL in standard `key=value` format, separated by '&'.
 * 
     */
    parameters?: Record<string, any>;
};

export namespace RedirectionResponse {

    /**
     * The HTTP status code associated with the redirection. This code indicates the type of redirection and the expected client behavior. Common redirection codes include 302 (Found) for temporary redirections and 301 (Moved Permanently) for permanent redirections. The specific code used may impact client caching and behavior.
 * 
     */
    export enum status_code {
        '_300' = 300,
        '_301' = 301,
        '_302' = 302,
        '_303' = 303,
        '_304' = 304,
        '_305' = 305,
        '_307' = 307,
        '_308' = 308,
    }


}
