/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * This payload represents a successful response to a request. It includes a valid JWT token for the next step in the workflow.
 */
export type SuccessTokenStepResponse = {
    /**
     * A valid JWT token for the next step in the workflow. It required aud, exp, and iat attributes. Audience contains the next_step path.
     */
    access_token: string;
    token_type: string;
    /**
     * Expiration timestamp in POSIX format.
     */
    expiration_time: number;
    /**
     * Issued at timestamp in POSIX format.
     */
    issued_at: number;
    /**
     * Not before timestamp in POSIX format.
     */
    not_before?: number;
    /**
     * A link or identifier for the next step in the workflow.
     */
    next_step: string;
};

export namespace SuccessTokenStepResponse {

    export enum token_type {
        BEARER = 'bearer',
    }


}
