import { TFunction } from "i18next";
import { DateType } from "../../../context/AccountContext";
import { InvalidValueException } from "../../../helper/exception/Exception";
import { t_warningTexts } from "../../../i18n/usualTexts/t_warningTexts";

class DateService {
  static intPattern = /^\d+$/;

  static daysOfMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  static currentDate = new Date();

  static currentDay = DateService.currentDate.getDate();
  static currentMonth = DateService.currentDate.getMonth() + 1;
  static currentYear = DateService.currentDate.getFullYear();

  valueMonths: Array<string>;

  field: string;

  day: string;
  month: string;
  year: string;

  d: number;
  m: number;
  y: number;

  t: TFunction<"translation", undefined>

  constructor(day: string, month: string, year: string, field: string, valueMonths: Array<string>, t: TFunction<"translation", undefined>) {
    this.valueMonths = valueMonths;

    this.field = field;

    this.day = day;
    this.month = month;
    this.year = year;

    this.y = parseInt(year);
    this.m = valueMonths.indexOf(month) + 1;
    this.d = parseInt(day);

    this.t = t;
  }

  validateYear() {
    if (
      this.y > DateService.currentYear ||
      this.y < 1900 ||
      !DateService.intPattern.test(this.year)
    ) {
      throw new InvalidValueException([this.field, this.t(t_warningTexts.date.year)]);
    }
  }

  validateMonth() {
    if (this.m === -1) {
      throw new InvalidValueException([this.field, this.t(t_warningTexts.date.month)]);
    }
  }

  validateDay() {
    DateService.daysOfMonth[2] = 28;
    if (this.y % 400 === 0 || (this.y % 4 === 0 && this.y % 100 !== 0)) {
      DateService.daysOfMonth[2] = 29;
    }

    if (
      !(0 < this.d && this.d <= DateService.daysOfMonth[this.m]) ||
      !DateService.intPattern.test(this.day)
    ) {
      throw new InvalidValueException([this.field, this.t(t_warningTexts.date.day)]);
    }
  }

  validateAge() {
    let age = DateService.currentYear - this.y;
    if (DateService.currentMonth <= this.m && DateService.currentDay < this.d) {
      age--;
    }

    if (age < 18) {
      throw new InvalidValueException([
        this.field,
        this.t(t_warningTexts.date.age),
      ]);
    }
  }
}

export default DateService;

export function dateFormat(object: DateType, valueMonths: Array<string>) {
  const month = (valueMonths.indexOf(object.month) + 1).toString()
  return object.year + "-" + (month.length === 1 ? "0" : "") + month + "-" + (object.day.length === 1 ? "0" : "") + object.day;
}