/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OAuth2Client } from './OAuth2Client';

export type AuthenticationSecondFactorValidationRequest = {
    /**
     * One-time Time-based access token (i.e.g. password take from authenticator app).
 * 
     */
    access_token: string;
    /**
     * Type of second-factor authentication to enable received from `/oauth2/user/password`.
     */
    factor_type: AuthenticationSecondFactorValidationRequest.factor_type;
    client?: OAuth2Client;
};

export namespace AuthenticationSecondFactorValidationRequest {

    /**
     * Type of second-factor authentication to enable received from `/oauth2/user/password`.
     */
    export enum factor_type {
        AUTHENTICATOR_APP = 'authenticator_app',
    }


}
