import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { t_popUp } from "../i18n/usualTexts/t_popUp";

interface Props {
  errorData: string;
  setErrorData: React.Dispatch<React.SetStateAction<string | null>>;
}

const ServerErrorPopUp: React.FC<Props> = ({ errorData, setErrorData }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    setErrorData(null)
    window.location.reload();
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          Ops...{" "}
          {errorData === "Network Error"
            ? t(t_popUp.titleNetworkError)
            : t(t_popUp.titleError)}
          .
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorData === "Network Error"
          ? t(t_popUp.bodyTextNetworkError)
          : t(t_popUp.bodyTextError)}
        {t(t_popUp.bodyText)} <b>{errorData}</b>.
        <br />
        {t(t_popUp.contactSubtitle)}: <a href="">example@example.com</a>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex-grow-1 text-rigth text-end">
          <Col className="p-0">
            <a
              style={{ color: "gray" }}
              href={"https://authien.k4d.com.br/probs"}
            >
              {t(t_popUp.moreDetailsLink)}
            </a>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ServerErrorPopUp;
