import React, { useState, ChangeEvent } from "react";

import { AccountType, useAccount } from "../../../context/AccountContext";

import { useNavigate } from "react-router-dom";

import AuthenticationForm from "../../../component/AuthenticationForm";
import FormField from "../../../component/Field";
import FormCheckBox from "../../../component/CheckBox";

import AccountService from "../service/AccountService";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ComposeURLWithGetQueryArguments } from "../../../helper/get";
import PagePaths from "../../../constant/PagePaths";
import { throwIfNotExpectedException } from "../../../helper/exception/Handler";
import { InvalidValueException } from "../../../helper/exception/Exception";
import Text from "../../../component/Text";
import TextButton from "../../../component/TextButton";
import { useTranslation } from "react-i18next";
import { t_createAccount } from "../../../i18n/usualTexts/t_createAccount";
import { t_help } from "../../../i18n/usualTexts/t_help";
import {
  cleanFormDataErrors,
  is_different_previous_value,
  set_localStorage,
} from "../../../helper/FormDataServices";
import FormSelect from "../../../component/Select";
import { string } from "prop-types";
import { countriesList } from "../../../constant/CountriesList";
import { delay } from "../../../helper/delay";
import { t_insertUsername } from "../../../i18n/usualTexts/t_insertUsername";

interface FormData {
  name: {
    value: string;
    errors: Set<string>;
  };
  surname: {
    value: string;
    errors: Set<string>;
  };
  nationality: {
    value: string;
    errors: Set<string>;
  };
  acceptTerms: {
    value: string;
    errors: Set<string>;
  };
}

const CreateAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  const { accountData, setAccountData } = useAccount();
  const [formData, setFormData] = useState<FormData>({
    name: {
      value: accountData.name ?? localStorage.getItem("name") ?? "",
      errors: new Set<string>(),
    },
    surname: {
      value: accountData.surname ?? localStorage.getItem("surname") ?? "",
      errors: new Set<string>(),
    },
    nationality: {
      value:
        accountData.nationality ?? localStorage.getItem("nationality") ?? "",
      errors: new Set<string>(),
    },
    acceptTerms: {
      value:
        accountData.acceptTerms ?? localStorage.getItem("acceptTerms") ?? "",
      errors: new Set<string>(),
    },
  });

  return (
    <AuthenticationForm
      title={t(t_createAccount.title)}
      subtitle={t(t_createAccount.subtitle)}
      onSubmit={() => handleSubmit()}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="name"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.name.value}
            errors={formData.name.errors}
            submitted={submitted}
          >
            {t(t_createAccount.namePlaceholder)}
          </FormField>
        </Col>
      </Row>

      <Row className="mt-4 px-0 m-0">
        <Col className="p-0 m-0">
          <FormField
            name="surname"
            type="text"
            onChange={handleFormData}
            value={formData.surname.value}
            errors={formData.surname.errors}
            submitted={submitted}
          >
            {t(t_createAccount.surnamePlaceholder)}
          </FormField>
        </Col>
      </Row>

      <Row className="mt-4 px-0 m-0">
        <Col className="p-0 m-0">
          <FormSelect
            name="nationality"
            required={true}
            onChange={handleFormSelect}
            value={formData.nationality.value}
            submitted={submitted}
            options={countriesList}
            errors={formData.nationality.errors}
          >
            {t(t_createAccount.nationalityPlaceholder)}
          </FormSelect>
        </Col>
      </Row>

      <Row className="mt-4 px-0 m-0">
        <Col className="p-0 m-0">
          <FormCheckBox
            name="acceptTerms"
            required={true}
            onChange={handleFormData}
            value={formData.acceptTerms.value}
            errors={formData.acceptTerms.errors}
            submitted={submitted}
          >
            <Text>{t(t_createAccount.serviceTermsCheck)}</Text>
            <TextButton>{t(t_createAccount.serviceTermsLink)}</TextButton>
          </FormCheckBox>
        </Col>
      </Row>

      <Row className="flex-grow-1 text-center text-lg-start mt-5">
        <Col className="text-end">
          <Button
            variant="primary"
            type="submit"
            style={{ width: "90px", height: "38px" }}
          >
            {t(t_help.nextButton)}
          </Button>
        </Col>
      </Row>
    </AuthenticationForm>
  );

  function handleFormData(
    e: ChangeEvent<HTMLInputElement>
  ) {
    const { value, name } = e.target;

    formData[name as keyof FormData].value = value;
    setFormData({
      ...formData,
    });
    cleanFormDataErrors(formData);
  }

  function handleFormSelect(selectValue: string, selectName: string) {
    formData[selectName as keyof FormData].value = selectValue;
    setFormData({
      ...formData,
    });
    cleanFormDataErrors(formData);
  }

  async function handleSubmit() {
    setSubmitted(true);
    try {
      if (!checkThisFormData()) return;

      post_and_navigate();
    } catch (e) {
      throwIfNotExpectedException(e, InvalidValueException);

      const errorDescription = (e as InvalidValueException).description;

      formData[errorDescription[0] as keyof FormData].errors.add(
        errorDescription[1]
      );
    } finally {
      set_localStorage(formData);

      setFormData({
        ...formData,
      });

      await delay(1000)
      setSubmitted(false);
    }
  }

  function post_and_navigate() {
    const step = 2;

    setAccountData({
      ...accountData,
      name: formData.name.value,
      surname: formData.surname.value,
      nationality: formData.nationality.value,
      acceptTerms: formData.acceptTerms.value,
      step: step,
    });

    set_localStorage(formData);

    navigate(
      ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.BIRTHDAYGENDER)
    );
  }

  function checkThisFormData() {
    const accountService: AccountService = new AccountService(t);

    accountService.checkName(
      formData.name.value,
      "name",
      t(t_createAccount.namePlaceholder)
    );
    if (formData.surname.value) {
      accountService.checkName(
        formData.surname.value,
        "surname",
        t(t_createAccount.surnamePlaceholder)
      );
    }

    if (!formData.acceptTerms.value) {
      return false;
    }

    return true;
  }
};

export default CreateAccountPage;
