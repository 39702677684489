import { OAuth2Type } from "../../../context/OAuth2Context";
import { getDeviceInfo } from "../../../helper/getDeviceInfo";
import { OpenAPI, ProblemResponse, RedirectionResponse, SuccessTokenStepResponse, UserAuthenticationService } from "../../../service/auth";

export async function StartAuthenticationRequest(oauth2Data: OAuth2Type): Promise<SuccessTokenStepResponse> {
  const startTransactionDatas = {
    device_info: await getDeviceInfo(),
    client: oauth2Data,
  };

  OpenAPI.TOKEN = undefined;

  const response = await UserAuthenticationService.startAuthenticationPost(
    startTransactionDatas
  );

  return response;
}

export async function IdentityRequest(
  oauth2Data: OAuth2Type,
  username: string,
  token: string | undefined
): Promise<SuccessTokenStepResponse> {
  const identityAuthenticationRequestDatas = {
    identity: username,
    client: oauth2Data,
  };

  OpenAPI.TOKEN = token;

  const response = await UserAuthenticationService.identityPost(
    identityAuthenticationRequestDatas
  );

  return response;
}

export async function PasswordRequest(
  oauth2Data: OAuth2Type,
  password: string,
  token: string | undefined,
): Promise<(SuccessTokenStepResponse | RedirectionResponse)> {
  const passwordAuthenticationRequestDatas = {
    password: password,
    client: oauth2Data,
  };

  OpenAPI.TOKEN = token;

  const response = await UserAuthenticationService.passwordPost(
    passwordAuthenticationRequestDatas
  );

  return response;
}
