import React from "react";
import { Row } from "react-bootstrap"
import { ThreeDots } from "react-loading-icons"

const LoadingPage = () => {
    return (
        <Row
          className="justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <ThreeDots style={{width: "100px"}} fill="lightblue"/>
        </Row>
    )
}

export default LoadingPage;