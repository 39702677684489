import React, { useState, ChangeEvent } from "react";

import { AccountType, useAccount } from "../../../context/AccountContext";

import { useNavigate } from "react-router-dom";

import AuthenticationForm from "../../../component/AuthenticationForm";
import FormField from "../../../component/Field";

import AccountService from "../service/AccountService";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ComposeURLWithGetQueryArguments } from "../../../helper/get";
import PagePaths from "../../../constant/PagePaths";
import { useTranslation } from "react-i18next";
import { t_insertUsername } from "../../../i18n/usualTexts/t_insertUsername";
import {
  cleanFormDataErrors,
} from "../../../helper/FormDataServices";
import {
  RegisterIdentityRequest,
} from "../service/AccountRequest";
import { delay, intervalMiliseconds } from "../../../helper/delay";
import { showAuthError } from "../service/RegisterShowError";
import {
  CacheService,
  IDENTITY_POST_RESPONSE,
  PERSONAL_INFO_POST_RESPONSE,
} from "../../../cache/cacheService";

export interface FormData {
  username: {
    value: string;
    errors: Set<string>;
  };
}

const InsertUsernamePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorPopUp, setServerErrorPopUp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { accountData, setAccountData } = useAccount();
  const [formData, setFormData] = useState<FormData>({
    username: {
      value: accountData.username ?? localStorage.getItem("username") ?? "",
      errors: new Set<string>(),
    },
  });

  return (
    <AuthenticationForm
      title={t(t_insertUsername.title)}
      subtitle={t(t_insertUsername.subtitle)}
      onSubmit={() => handleSubmit()}
      isLoading={isLoading}
      back={back}
      serverErrorPopUp={serverErrorPopUp}
      setServerErrorPopUp={setServerErrorPopUp}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="username"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.username.value}
            errors={formData.username.errors}
            submitted={submitted}
          >
            {t(t_insertUsername.emailPlaceholder)}
          </FormField>
        </Col>
      </Row>
    </AuthenticationForm>
  );

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    if (name === "acceptTerms") {
      const { checked } = e.target;
      formData[name as keyof FormData].value = checked ? "true" : "";
    } else {
      formData[name as keyof FormData].value = value;
    }

    setFormData({
      ...formData,
    });
  }

  function back() {
    setAccountData({
      ...accountData,
      step: 2,
    });

    navigate(ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.BIRTHDAYGENDER));
  }

  async function handleSubmit() {
    setSubmitted(true);
    setIsLoading(true);

    const step = 4;

    const newAccountData: AccountType = {
      ...accountData,
      username: formData.username.value,
      step: step,
    };

    const cache = new CacheService(newAccountData, IDENTITY_POST_RESPONSE, accountData);
    try {
      checkThisFormData();

      await postFormDatas_and_navigate(cache, newAccountData);
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      showAuthError(error, setServerErrorPopUp, frontExceptionFunction, t);
      
      cache.postToCache(error);
    } finally {
      setIsLoading(false);

      setFormData({
        ...formData,
      });

      await delay(1000);
      setSubmitted(false);
    }
  }

  async function postFormDatas_and_navigate(
    cache: CacheService,
    newAccountData: AccountType
  ) {
    
    if (!cache.getToCache()) {
      const personalInfoPostResponse = cache.getToCache(PERSONAL_INFO_POST_RESPONSE);

      const intervalMilisecondsValue = intervalMiliseconds(
        personalInfoPostResponse
      );
      await delay(intervalMilisecondsValue);

      const identityPostResponse = await RegisterIdentityRequest(
        formData.username.value,
        personalInfoPostResponse?.access_token
      );
      console.log(identityPostResponse);

      cache.postToCache(identityPostResponse);
    }

    setAccountData(newAccountData);

    navigate(ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.CREATEPASSWORD));
  }

  function checkThisFormData() {
    const accountService: AccountService = new AccountService(t);
    cleanFormDataErrors(formData);
    accountService.checkUsername(formData.username.value, "username");
  }

  function frontExceptionFunction(errorDescription: Array<string>) {
    formData[errorDescription[0] as keyof FormData].errors.add(
      errorDescription[1]
    );
  }
};

export default InsertUsernamePage;
