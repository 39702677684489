import React from "react";

import Divider from "./Divider";
import GoogleButton from "../component/button/Google";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

const ExternalAuthentication: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col>
          <Divider>{t('login.identity.orConnectWithText')}</Divider>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex flex-row align-items-center justify-content-center">
          <GoogleButton link="" />
        </Col>
      </Row>
    </>
  );
};

export default ExternalAuthentication;
