import React, { ReactNode, useEffect } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import PagePaths from "../constant/PagePaths";
import { ComposeURLWithGetQueryArguments } from "../helper/get";
import { useAccount } from "../context/AccountContext";

type RenderAccountProps = { children: ReactNode };
export const RenderAccount = ({ children }: RenderAccountProps) => {
  const navigate: NavigateFunction = useNavigate();

  const { accountData } = useAccount();

  const location = useLocation();

  useEffect(() => {
    const stepPageMapping: { [key: number]: string } = {
      1: PagePaths.SIGNUP.CREATEACCOUNT,
      2: PagePaths.SIGNUP.BIRTHDAYGENDER,
      3: PagePaths.SIGNUP.INSERTUSERNAME,
      4: PagePaths.SIGNUP.CREATEPASSWORD,
      5: PagePaths.SIGNUP.VERIFYEMAIL,
      6: PagePaths.SIGNUP.CONGRATULATIONS,
    };

    const currentStep = accountData.step ?? 1;

    const currentPage = location.pathname;

    if (!currentPage.startsWith("/signup/")) return;

    if (currentPage !== stepPageMapping[currentStep]) {
      navigate(ComposeURLWithGetQueryArguments(stepPageMapping[1]));
      return;
    }

    navigate(ComposeURLWithGetQueryArguments(stepPageMapping[currentStep]));
  }, [accountData, navigate, location.pathname]);

  return <>{children}</>;
};

