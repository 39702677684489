export const countriesList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Ascension Island",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Caribbean Netherlands",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo - Brazzaville",
  "Congo - Kinshasa",
  "Cook Islands",
  "Costa Rica",
  "Côte d’Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (Islas Malvinas",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "North Macedonia",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Samoa",
  "San Marino",
  "São Tomé & Príncipe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "St. Barthélemy",
  "St. Helena",
  "St. Kitts & Nevis",
  "St. Lucia",
  "St. Martin",
  "St. Pierre & Miquelon",
  "St. Vincent & Grenadines",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Türkiye",
  "Turkmenistan",
  "Turks & Caicos Islands",
  "Tuvalu",
  "U.S. Virgin Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Wallis & Futuna",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

export const countriesListWithIcons = [
  { value: 'Afghanistan', icon: 'af' },
  { value: 'Albania', icon: 'al' },
  { value: 'Algeria', icon: 'dz' },
  { value: 'American Samoa', icon: 'as' },
  { value: 'Andorra', icon: 'ad' },
  { value: 'Angola', icon: 'ao' },
  { value: 'Anguilla', icon: 'ai' },
  { value: 'Antigua & Barbuda', icon: 'ag' },
  { value: 'Argentina', icon: 'ar' },
  { value: 'Armenia', icon: 'am' },
  { value: 'Aruba', icon: 'aw' },
  { value: 'Ascension Island', icon: 'ac' },
  { value: 'Australia', icon: 'au' },
  { value: 'Austria', icon: 'at' },
  { value: 'Azerbaijan', icon: 'az' },
  { value: 'Bahamas', icon: 'bs' },
  { value: 'Bahrain', icon: 'bh' },
  { value: 'Bangladesh', icon: 'bd' },
  { value: 'Barbados', icon: 'bb' },
  { value: 'Belarus', icon: 'by' },
  { value: 'Belgium', icon: 'be' },
  { value: 'Belize', icon: 'bz' },
  { value: 'Benin', icon: 'bj' },
  { value: 'Bermuda', icon: 'bm' },
  { value: 'Bhutan', icon: 'bt' },
  { value: 'Bolivia', icon: 'bo' },
  { value: 'Bosnia & Herzegovina', icon: 'ba' },
  { value: 'Botswana', icon: 'bw' },
  { value: 'Brazil', icon: 'br' },
  { value: 'British Indian Ocean Territory', icon: 'io' },
  { value: 'British Virgin Islands', icon: 'vg' },
  { value: 'Brunei', icon: 'bn' },
  { value: 'Bulgaria', icon: 'bg' },
  { value: 'Burkina Faso', icon: 'bf' },
  { value: 'Burundi', icon: 'bi' },
  { value: 'Cambodia', icon: 'kh' },
  { value: 'Cameroon', icon: 'cm' },
  { value: 'Canada', icon: 'ca' },
  { value: 'Cape Verde', icon: 'cv' },
  { value: 'Caribbean Netherlands', icon: 'bq' },
  { value: 'Cayman Islands', icon: 'ky' },
  { value: 'Central African Republic', icon: 'cf' },
  { value: 'Chad', icon: 'td' },
  { value: 'Chile', icon: 'cl' },
  { value: 'China', icon: 'cn' },
  { value: 'Colombia', icon: 'co' },
  { value: 'Comoros', icon: 'km' },
  { value: 'Congo - Brazzaville', icon: 'cg' },
  { value: 'Congo - Kinshasa', icon: 'cd' },
  { value: 'Cook Islands', icon: 'ck' },
  { value: 'Costa Rica', icon: 'cr' },
  { value: 'Côte d’Ivoire', icon: 'ci' },
  { value: 'Croatia', icon: 'hr' },
  { value: 'Cuba', icon: 'cu' },
  { value: 'Curaçao', icon: 'cw' },
  { value: 'Cyprus', icon: 'cy' },
  { value: 'Czechia', icon: 'cz' },
  { value: 'Denmark', icon: 'dk' },
  { value: 'Djibouti', icon: 'dj' },
  { value: 'Dominica', icon: 'dm' },
  { value: 'Dominican Republic', icon: 'do' },
  { value: 'Ecuador', icon: 'ec' },
  { value: 'Egypt', icon: 'eg' },
  { value: 'El Salvador', icon: 'sv' },
  { value: 'Equatorial Guinea', icon: 'gq' },
  { value: 'Eritrea', icon: 'er' },
  { value: 'Estonia', icon: 'ee' },
  { value: 'Eswatini', icon: 'sz' },
  { value: 'Ethiopia', icon: 'et' },
  { value: 'Falkland Islands (Islas Malvinas', icon: 'fk' },
  { value: 'Faroe Islands', icon: 'fo' },
  { value: 'Fiji', icon: 'fj' },
  { value: 'Finland', icon: 'fi' },
  { value: 'France', icon: 'fr' },
  { value: 'French Guiana', icon: 'gf' },
  { value: 'French Polynesia', icon: 'pf' },
  { value: 'Gabon', icon: 'ga' },
  { value: 'Gambia', icon: 'gm' },
  { value: 'Georgia', icon: 'ge' },
  { value: 'Germany', icon: 'de' },
  { value: 'Ghana', icon: 'gh' },
  { value: 'Gibraltar', icon: 'gi' },
  { value: 'Greece', icon: 'gr' },
  { value: 'Greenland', icon: 'gl' },
  { value: 'Grenada', icon: 'gd' },
  { value: 'Guadeloupe', icon: 'gp' },
  { value: 'Guam', icon: 'gu' },
  { value: 'Guatemala', icon: 'gt' },
  { value: 'Guinea', icon: 'gn' },
  { value: 'Guinea-Bissau', icon: 'gw' },
  { value: 'Guyana', icon: 'gy' },
  { value: 'Haiti', icon: 'ht' },
  { value: 'Honduras', icon: 'hn' },
  { value: 'Hong Kong', icon: 'hk' },
  { value: 'Hungary', icon: 'hu' },
  { value: 'Iceland', icon: 'is' },
  { value: 'India', icon: 'in' },
  { value: 'Indonesia', icon: 'id' },
  { value: 'Iran', icon: 'ir' },
  { value: 'Iraq', icon: 'iq' },
  { value: 'Ireland', icon: 'ie' },
  { value: 'Israel', icon: 'il' },
  { value: 'Italy', icon: 'it' },
  { value: 'Jamaica', icon: 'jm' },
  { value: 'Japan', icon: 'jp' },
  { value: 'Jordan', icon: 'jo' },
  { value: 'Kazakhstan', icon: 'kz' },
  { value: 'Kenya', icon: 'ke' },
  { value: 'Kiribati', icon: 'ki' },
  { value: 'Kosovo', icon: 'xk' },
  { value: 'Kuwait', icon: 'kw' },
  { value: 'Kyrgyzstan', icon: 'kg' },
  { value: 'Laos', icon: 'la' },
  { value: 'Latvia', icon: 'lv' },
  { value: 'Lebanon', icon: 'lb' },
  { value: 'Lesotho', icon: 'ls' },
  { value: 'Liberia', icon: 'lr' },
  { value: 'Libya', icon: 'ly' },
  { value: 'Liechtenstein', icon: 'li' },
  { value: 'Lithuania', icon: 'lt' },
  { value: 'Luxembourg', icon: 'lu' },
  { value: 'Macao', icon: 'mo' },
  { value: 'Madagascar', icon: 'mg' },
  { value: 'Malawi', icon: 'mw' },
  { value: 'Malaysia', icon: 'my' },
  { value: 'Maldives', icon: 'mv' },
  { value: 'Mali', icon: 'ml' },
  { value: 'Malta', icon: 'mt' },
  { value: 'Marshall Islands', icon: 'mh' },
  { value: 'Martinique', icon: 'mq' },
  { value: 'Mauritania', icon: 'mr' },
  { value: 'Mauritius', icon: 'mu' },
  { value: 'Mexico', icon: 'mx' },
  { value: 'Micronesia', icon: 'fm' },
  { value: 'Moldova', icon: 'md' },
  { value: 'Monaco', icon: 'mc' },
  { value: 'Mongolia', icon: 'mn' },
  { value: 'Montenegro', icon: 'me' },
  { value: 'Montserrat', icon: 'ms' },
  { value: 'Morocco', icon: 'ma' },
  { value: 'Mozambique', icon: 'mz' },
  { value: 'Myanmar (Burma', icon: 'mm' },
  { value: 'Namibia', icon: 'na' },
  { value: 'Nauru', icon: 'nr' },
  { value: 'Nepal', icon: 'np' },
  { value: 'Netherlands', icon: 'nl' },
  { value: 'New Caledonia', icon: 'nc' },
  { value: 'New Zealand', icon: 'nz' },
  { value: 'Nicaragua', icon: 'ni' },
  { value: 'Niger', icon: 'ne' },
  { value: 'Nigeria', icon: 'ng' },
  { value: 'Niue', icon: 'nu' },
  { value: 'Norfolk Island', icon: 'nf' },
  { value: 'North Korea', icon: 'kp' },
  { value: 'North Macedonia', icon: 'mk' },
  { value: 'Norway', icon: 'no' },
  { value: 'Oman', icon: 'om' },
  { value: 'Pakistan', icon: 'pk' },
  { value: 'Palau', icon: 'pw' },
  { value: 'Palestine', icon: 'ps' },
  { value: 'Panama', icon: 'pa' },
  { value: 'Papua New Guinea', icon: 'pg' },
  { value: 'Paraguay', icon: 'py' },
  { value: 'Peru', icon: 'pe' },
  { value: 'Philippines', icon: 'ph' },
  { value: 'Poland', icon: 'pl' },
  { value: 'Portugal', icon: 'pt' },
  { value: 'Puerto Rico', icon: 'pr' },
  { value: 'Qatar', icon: 'qa' },
  { value: 'Réunion', icon: 're' },
  { value: 'Romania', icon: 'ro' },
  { value: 'Russia', icon: 'ru' },
  { value: 'Rwanda', icon: 'rw' },
  { value: 'Samoa', icon: 'ws' },
  { value: 'San Marino', icon: 'sm' },
  { value: 'São Tomé & Príncipe', icon: 'st' },
  { value: 'Saudi Arabia', icon: 'sa' },
  { value: 'Senegal', icon: 'sn' },
  { value: 'Serbia', icon: 'rs' },
  { value: 'Seychelles', icon: 'sc' },
  { value: 'Sierra Leone', icon: 'sl' },
  { value: 'Singapore', icon: 'sg' },
  { value: 'Sint Maarten', icon: 'sx' },
  { value: 'Slovakia', icon: 'sk' },
  { value: 'Slovenia', icon: 'si' },
  { value: 'Solomon Islands', icon: 'sb' },
  { value: 'Somalia', icon: 'so' },
  { value: 'South Africa', icon: 'za' },
  { value: 'South Korea', icon: 'kr' },
  { value: 'South Sudan', icon: 'ss' },
  { value: 'Spain', icon: 'es' },
  { value: 'Sri Lanka', icon: 'lk' },
  { value: 'St. Barthélemy', icon: 'bl' },
  { value: 'St. Helena', icon: 'sh' },
  { value: 'St. Kitts & Nevis', icon: 'kn' },
  { value: 'St. Lucia', icon: 'lc' },
  { value: 'St. Martin', icon: 'mf' },
  { value: 'St. Pierre & Miquelon', icon: 'pm' },
  { value: 'St. Vincent & Grenadines', icon: 'vc' },
  { value: 'Sudan', icon: 'sd' },
  { value: 'Suriname', icon: 'sr' },
  { value: 'Sweden', icon: 'se' },
  { value: 'Switzerland', icon: 'ch' },
  { value: 'Syria', icon: 'sy' },
  { value: 'Taiwan', icon: 'tw' },
  { value: 'Tajikistan', icon: 'tj' },
  { value: 'Tanzania', icon: 'tz' },
  { value: 'Thailand', icon: 'th' },
  { value: 'Timor-Leste', icon: 'tl' },
  { value: 'Togo', icon: 'tg' },
  { value: 'Tokelau', icon: 'tk' },
  { value: 'Tonga', icon: 'to' },
  { value: 'Trinidad & Tobago', icon: 'tt' },
  { value: 'Tunisia', icon: 'tn' },
  { value: 'Türkiye', icon: 'tr' },
  { value: 'Turkmenistan', icon: 'tm' },
  { value: 'Turks & Caicos Islands', icon: 'tc' },
  { value: 'Tuvalu', icon: 'tv' },
  { value: 'U.S. Virgin Islands', icon: 'vi' },
  { value: 'Uganda', icon: 'ug' },
  { value: 'Ukraine', icon: 'ua' },
  { value: 'United Arab Emirates', icon: 'ae' },
  { value: 'United Kingdom', icon: 'gb' },
  { value: 'United States', icon: 'us' },
  { value: 'Uruguay', icon: 'uy' },
  { value: 'Uzbekistan', icon: 'uz' },
  { value: 'Vanuatu', icon: 'vu' },
  { value: 'Vatican City', icon: 'va' },
  { value: 'Venezuela', icon: 've' },
  { value: 'Vietnam', icon: 'vn' },
  { value: 'Wallis & Futuna', icon: 'wf' },
  { value: 'Yemen', icon: 'ye' },
  { value: 'Zambia', icon: 'zm' },
  { value: 'Zimbabwe', icon: 'zw' }
];