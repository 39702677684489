/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RefreshTokenGrant = {
    /**
     * Grant type for refresh token
     */
    grant_type: RefreshTokenGrant.grant_type;
    /**
     * Refresh token used to obtain new access token
     */
    refresh_token: string;
    /**
     * Client identifier issued to the client during registration
     */
    client_id: string;
    /**
     * Client secret known only to the client and authorization server
     */
    client_secret: string;
};

export namespace RefreshTokenGrant {

    /**
     * Grant type for refresh token
     */
    export enum grant_type {
        REFRESH_TOKEN = 'refresh_token',
    }


}
