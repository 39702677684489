import React from "react";
import PropTypes from "prop-types";

const ErrorPage = ({ missingArguments = [] }) => {
  return (
    <>
      <h3>Page Not Found</h3>
      {missingArguments.length > 0 && (
        <p>
          The following arguments are required: {missingArguments.join(", ")}.
        </p>
      )}
    </>
  );
};

ErrorPage.propTypes = {
  missingArguments: PropTypes.array,
};

export default ErrorPage;
