import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

const classes = {
  primary: "link-primary",
  secondary: "link-secondary",
};

const TextButton = ({
  children,
  variant = "primary",
  url = "#",
  onClick = () => {},
}) => {
  const selectedClass = classes[variant] || "link-primary";

  return (
    <a
      href={url}
      className={`${selectedClass} text-decoration-none`}
      onClick={onClick}
    >
      {children} 
    </a>
  );
};

TextButton.propTypes = {
  children: PropTypes.string,
  variant: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
};

export default TextButton;
