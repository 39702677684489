/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PersonalInformationRequest } from '../models/PersonalInformationRequest';
import type { RegisterCommitRequest } from '../models/RegisterCommitRequest';
import type { RegisterIdentityRequest } from '../models/RegisterIdentityRequest';
import type { RegisterPasswordRequest } from '../models/RegisterPasswordRequest';
import type { StartTransactionRequest } from '../models/StartTransactionRequest';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { SuccessTokenStepResponse } from '../models/SuccessTokenStepResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserRegistrationService {

    /**
     * Start user registration transaction
     * @param requestBody 
     * @returns SuccessTokenStepResponse Transaction started successfully
     * @throws ApiError
     */
    public static postAccountRegisterStartTransaction(
requestBody: StartTransactionRequest,
): CancelablePromise<SuccessTokenStepResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/register/start_transaction',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Register personal information of a new user
     * Endpoint to register name, birthdate, gender, and others personal information of a new user. It depends on the token generated in the previous step in `/account/register/start_transaction`.
     * @param requestBody 
     * @returns SuccessTokenStepResponse Transaction step completed successfully.
     * @throws ApiError
     */
    public static accountRegisterPersonalInfoPost(
requestBody: PersonalInformationRequest,
): CancelablePromise<SuccessTokenStepResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/register/personal_information',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Register identity of a new user
     * Endpoint to register the user identity. It dependents on the token generated in the previous step `/account/register/personal_information`.
     * @param requestBody 
     * @returns SuccessTokenStepResponse Transaction step completed successfully.
     * @throws ApiError
     */
    public static accountRegisterIdentityPost(
requestBody: RegisterIdentityRequest,
): CancelablePromise<SuccessTokenStepResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/register/identity',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Register password of a new user
     * Endpoint to register the user password. It dependents on the token generated in the previous step `/account/register/identity`. It send a confirmation code to the user e-mail that should be used to confirm its e-mail address.
     * @param requestBody 
     * @returns SuccessTokenStepResponse Transaction step completed successfully.
     * @throws ApiError
     */
    public static accountRegisterPasswordPost(
requestBody: RegisterPasswordRequest,
): CancelablePromise<SuccessTokenStepResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/register/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Commit user registration
     * Endpoint to commit the user registration after completing all the required steps.
     * @param requestBody 
     * @returns SuccessResponse User registration committed successfully. User has been created.
     * @throws ApiError
     */
    public static accountRegisterCommitPost(
requestBody: RegisterCommitRequest,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/register/commit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
