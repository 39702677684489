import { AccountType } from "../../../context/AccountContext";
import { OAuth2Type } from "../../../context/OAuth2Context";
import { getDeviceInfo } from "../../../helper/getDeviceInfo";
import {
  OpenAPI,
  SuccessResponse,
  SuccessTokenStepResponse,
  UserRegistrationService,
} from "../../../service/auth";

export async function StartTransactionRequest(
  oauth2Data: OAuth2Type
): Promise<SuccessTokenStepResponse> {
  const deviceInfos = await getDeviceInfo();

  const startTransactionDatas = {
    device_info: deviceInfos,
    client: oauth2Data,
  };

  OpenAPI.TOKEN = undefined;

  const response =
    await UserRegistrationService.postAccountRegisterStartTransaction(
      startTransactionDatas
    );

  return response;
}

export async function RegisterPersonalInformationRequest(
  accountData: AccountType,
  gender: string,
  pronoun: string,
  birthdate: string,
  token: string | undefined
): Promise<SuccessTokenStepResponse> {
  const personalInfoDatas = {
    first_name: accountData.name,
    last_name: accountData.surname,
    terms: Boolean(accountData.acceptTerms),
    gender: gender,
    pronoun: pronoun, 
    birthdate: birthdate,
    nationality: accountData.nationality, 
  };

  OpenAPI.TOKEN = token;

  const response =
    await UserRegistrationService.accountRegisterPersonalInfoPost(
      personalInfoDatas
    );

  return response;
}

export async function RegisterIdentityRequest(
  email: string,
  token: string | undefined
): Promise<SuccessTokenStepResponse> {
  const identityDatas = {
    email: email,
  };

  OpenAPI.TOKEN = token;

  const response = await UserRegistrationService.accountRegisterIdentityPost(
    identityDatas
  );

  return response;
}

export async function RegisterPasswordRequest(
  password: string, 
  passwordConfirmation: string,
  token: string | undefined
): Promise<SuccessTokenStepResponse> {
  const passwordDatas = {
    password: password,
    password_confirmation: passwordConfirmation,
  };

  OpenAPI.TOKEN = token;

  const response = await UserRegistrationService.accountRegisterPasswordPost(
    passwordDatas
  );

  return response;
}

export async function RegisterCommitPost(
  confirmationCode: string,
  token: string | undefined
): Promise<SuccessResponse> {
  const commitDatas = {
    confirmation_code: parseInt(confirmationCode),
  };

  OpenAPI.TOKEN = token;

  const response = await UserRegistrationService.accountRegisterCommitPost(
    commitDatas
  );

  return response;
}
