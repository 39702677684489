export interface ErrorPayload {
  error: {
    description: string;
    status: number;
  };
}

export class Exception {
  private description: string;
  private status: number;

  constructor(description: string, status: number) {
    this.description = description;
    this.status = status;
  }

  toJSON(): ErrorPayload {
    return {
      error: {
        description: this.description,
        status: this.status,
      },
    };
  }
}

export class InvalidException {
  description: string | Array<string>;
  status: number;

  constructor(description: string | Array<string>, status: number) {
    this.description = description;
    this.status = status;
  }
}

export class InvalidUserException extends Exception {
  constructor(username: string) {
    super(`User ${username} is not in the correct format.`, 400);
  }
}

export class InvalidCredentialException extends Exception {
  constructor() {
    super("Incorrect password format.", 400);
  }
}

export class OAuth2ClientNotFoundException extends Exception {
  constructor() {
    super("Client not found", 404);
  }
}

export class InvalidRedictURIException extends Exception {
  constructor() {
    const description: string =
      "The redirect_uri parameter does not match the registered redirect URI for this client.";
    super(description, 400);
  }
}

export class InvalidValueException extends InvalidException {
  constructor(description: string | Array<string>) {
    super(description, 400);
  }
}
