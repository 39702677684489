import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

const Text = ({ children }) => {
  return <span>{children} </span>;
};

Text.propTypes = {
  children: PropTypes.string,
};

export default Text;
