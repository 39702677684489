// Access the strings used on the identity page.
export const t_identity = {
  title: "login.identity.title",
  subtitle: "login.identity.subtitle",
  emailPlaceholder: "login.identity.emailPlaceholder",
  forgotEmailLink: "login.identity.forgotEmailLink",
  guestModeText: "login.identity.guestModeText",
  guestModeLink: "login.identity.guestModeLink",
  createAccountButton: "login.identity.createAccountButton",
  nextButton: "login.identity.nextButton",
  orConnectWithText: "login.identity.odConnectWithText",
};
