// Access the strings used on the service components.
export type   T_oAuth2Type = {
  username: { formatError: string; invalidException: string };
  password: { formatError: string; invalidException: string };
};

export type T_warningTexts = {
  OAuth2Type: {
    username: { formatError: string; invalidException: string };
    password: { formatError: string; invalidException: string };
  };

  signup: {
    confirmPassword: string;
    date: string;
    name: string;
    password: string;
    select: string;
    username: string;
    code: string;
  };

  date: {
    year: string;
    month: string;
    day: string;
    age: string;
  };
};

export const t_warningTexts = {
  oAuth2: {
    username: {
      formatError: "warningTexts.oAuth2.usernameFormatError",
      invalidException: "warningTexts.oAuth2.usernameInvalidException",
    },
    password: {
      formatError: "warningTexts.oAuth2.passwordFormatError",
      invalidException: "warningTexts.oAuth2.passwordInvalidException",
    },
  },

  signup: {
    confirmPassword: "warningTexts.signup.confirmPassword",
    date: "warningTexts.signup.date",
    name: "warningTexts.signup.name",
    password: {
      beginning: "warningTexts.signup.password.beginning",
      length: "warningTexts.signup.password.length",
      number: "warningTexts.signup.password.number",
      uppercase: "warningTexts.signup.password.uppercase",
      lowercase: "warningTexts.signup.password.lowercase",
      specialCharacter: "warningTexts.signup.password.specialCharacter",
    },
    select: "warningTexts.signup.select",
    username: "warningTexts.signup.username",
    usernameAlreadyExistsError: "warningTexts.signup.usernameAlreadyExistsError",
    code: "warningTexts.signup.code",
  },

  date: {
    year: "warningTexts.date.year",
    month: "warningTexts.date.month",
    day: "warningTexts.date.day",
    age: "warningTexts.date.age",
  },
};
