import React from "react";
import "../assets/css/base.css";

const K4DFormHeader: React.FC = () => {
  return (
    <div className="mb-3 text-center">
      <div className="row text-start border-bottom py-2 m-0">
        <div className="col " style={{ height: "25px" }}>
          <div className="k4d-logo"></div>
        </div>
        <div className="col col-auto d-flex align-items-center"></div>
      </div>
    </div>
  );
};

export default K4DFormHeader;
